import { FC, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMemoizedFn, useSafeState } from 'ahooks';
import { Form, Input, message, Tabs, Checkbox } from 'antd';
import * as Api from '@/api';
import { REDIRECT_DELAY } from '@/utils/const';
import { HomeContext } from '../../context/context';
import Captcha from '@/components/captcha';
import Wrapper from '@/components/login-wrapper';
import { inRedirectWhitelist } from '@/utils/verify';
import { asyncCookie } from '@/utils/cookie';
import FormItem from '@/components/form-item';
import './index.scss';

const Register: FC = () => {
  const navigate = useNavigate();
  const context = useContext(HomeContext);
  const ref = useRef(null);
  // const [loading, setLoading] = useSafeState(false);
  const [address, setAddress] = useSafeState('');

  const onFinish = useMemoizedFn(async (values) => {
    // setLoading(true);
    delete values.confirmPassword;
    const res = await Api.register?.(values);
    if (res.success) {
      try {
        asyncCookie(res.result);
        message.success(
          context.intl.formatMessage({
            id: 'account.register.success',
          }),
        );
        // setLoading(false);
        setTimeout(() => {
          setTimeout(() => {
            form.resetFields();
          }, 1000);
          if (inRedirectWhitelist(context.redirectUrl)) {
            window.location.replace(context.redirectUrl);
          } else {
            navigate(
              {
                ...window.location,
                search: '',
                pathname: '/center',
              },
              {
                replace: true,
              },
            );
          }
        }, REDIRECT_DELAY);
      } catch {
        // setLoading(false);
      }
    } else {
      // setLoading(false);
    }
  });

  const [form] = Form.useForm();

  return (
    <Wrapper>
      <div ref={ref} className="register-container">
        <div className="welcome-message">
          <FormattedMessage id="account.welcome" />
        </div>
        <Form
          form={form}
          autoComplete="off"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
        >
          <Tabs centered className="header-tabs" activeKey="register">
            <Tabs.TabPane
              className="header-tab"
              tab={<FormattedMessage id="account.register.account" />}
              key="register"
            />
          </Tabs>
          <FormItem.Account name="registerNo">
            <Input
              placeholder={`${context.intl.formatMessage({
                id: 'account.please.input',
              })}${context.intl.formatMessage({
                id: 'account.mobile.or.email',
              })}`}
              onChange={(e) => setAddress(e.target.value)}
              allowClear
            />
          </FormItem.Account>
          <FormItem.Password name="password">
            <Input.Password
              placeholder={`${context.intl.formatMessage({
                id: 'account.please.input',
              })}${context.intl.formatMessage({
                id: 'account.password',
              })}`}
              allowClear
            />
          </FormItem.Password>
          <FormItem.Password
            name="confirmPassword"
            validateFirst
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!!value && value !== getFieldValue('password')) {
                    return Promise.reject(
                      new Error(
                        context.intl.formatMessage({
                          id: 'account.password.is.different',
                        }),
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={`${context.intl.formatMessage({
                id: 'account.please.reinput',
              })}${context.intl.formatMessage({
                id: 'account.password',
              })}`}
              allowClear
            />
          </FormItem.Password>
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error(
                        context.intl.formatMessage({
                          id: 'account.agreement.required.message',
                        }),
                      ),
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            style={{ marginBottom: 0 }}
          >
            <Checkbox>
              <FormattedMessage id="account.agreement.agree" />
              <a
                rel="noreferrer"
                target="_blank"
                href="https://eiot6.com/docs/vgxeib?hide_aside=1"
              >
                {context.intl.formatMessage({
                  id: 'account.service.agreement',
                })}
              </a>
              、
              <a
                rel="noreferrer"
                target="_blank"
                href="https://eiot6.com/docs/ogcp1s?hide_aside=1"
              >
                {context.intl.formatMessage({
                  id: 'account.privacy.policy',
                })}
              </a>
            </Checkbox>
          </Form.Item>
          <FormItem.Captcha name="verificationCode" noStyle>
            <Captcha
              form={form}
              scene="REGISTER"
              wrapperRef={ref.current}
              address={address}
              onFinish={form.submit}
              verifyFields={[
                'registerNo',
                'confirmPassword',
                'password',
                'agreement',
              ]}
            />
          </FormItem.Captcha>
          <div className="compact-row flex-center">
            <Link
              to={{
                ...window.location,
                pathname: '/login',
              }}
              replace
              className="btn-link-login"
            >
              <FormattedMessage id="account.login.existing.account" />
            </Link>
          </div>
        </Form>
      </div>
    </Wrapper>
  );
};

export default Register;
