import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RouterGuadian: FC = ({ children }) => {
  const loc = useLocation();
  const route = useNavigate();
  const search = window.location.search;

  useEffect(() => {
    if (loc.pathname === '/') {
      route(`/center${search}`, {
        replace: true,
      });
    } else if (loc.pathname === '/center') {
      route(`/center/basic${search}`, {
        replace: true,
      });
    }
  }, [loc.pathname, route, search]);

  return <>{children}</>;
};

export default RouterGuadian;
