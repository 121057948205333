import { FC, useContext, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Input, Button, Form, message, Tabs } from 'antd';
import { useMemoizedFn, useSafeState } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import Wrapper from '@/components/login-wrapper';
import Captcha from '@/components/captcha';
import FormItem from '@/components/form-item';
import * as Api from '@/api';
import { HomeContext } from '@/context/context';
import './index.scss';

const AddressConfirm: FC<{
  wrapperRef: any;
  onOk: Function;
}> = (props) => {
  const context = useContext(HomeContext);
  const { wrapperRef, onOk } = props;
  const [form] = Form.useForm();
  const [address, setAddress] = useSafeState('');
  const captchaNextButton = useRef<any>(null);

  const onFinish = useMemoizedFn(async (values) => {
    if (!values.code) return;
    const res = await Api.getAuthTicket(values);

    if (res.success) {
      onOk(res.result.authTicket);
    }
  });

  const onAddressChange = useMemoizedFn((e) => {
    setAddress(e.target.value);
  });

  return (
    <Form form={form} onFinish={onFinish}>
      <FormItem.Account name="address">
        <Input
          onChange={onAddressChange}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              captchaNextButton.current?.onNextStep?.();
            }
          }}
          placeholder={`${context.intl.formatMessage({
            id: 'account.please.input',
          })}${context.intl.formatMessage({
            id: 'account.mobile.or.email',
          })}`}
          allowClear
        />
      </FormItem.Account>
      <FormItem.Captcha name="code">
        <Captcha
          form={form}
          scene="RESET_PASSWORD"
          wrapperRef={wrapperRef}
          address={address}
          onFinish={form.submit}
          verifyFields={['address']}
        />
      </FormItem.Captcha>
    </Form>
  );
};

const ResetPassword: FC<{ authTicket: string }> = (props) => {
  const context = useContext(HomeContext);
  const navigate = useNavigate();
  const { authTicket } = props;
  const [form] = Form.useForm();

  const onFinish = useMemoizedFn(async (values) => {
    const res = await Api.resetPassword({
      ...values,
      authTicket,
    });

    if (res.success) {
      message.success(
        context.intl.formatMessage({
          id: 'center.reset.success',
        }),
      );

      setTimeout(() => {
        navigate(
          {
            ...window.location,
            pathname: '/login',
          },
          {
            replace: true,
          },
        );
      }, 1000);
    }
  });

  return (
    <Form form={form} onFinish={onFinish}>
      <FormItem.Password name="newPassword">
        <Input.Password
          placeholder={`${context.intl.formatMessage({
            id: 'account.please.input',
          })}${context.intl.formatMessage({
            id: 'account.password',
          })}`}
          allowClear
        />
      </FormItem.Password>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          <FormattedMessage id="account.finish" />
        </Button>
      </Form.Item>
    </Form>
  );
};

const Forget: FC = () => {
  const [ref, setRef] = useSafeState<HTMLElement | null>(null);
  const [step, setStep] = useSafeState<1 | 2>(1);

  useEffect(() => {
    setRef(document.getElementById('forget_container'));
    setStep(1);
  }, [setRef, setStep]);

  const [authTicket, setAuthTicket] = useSafeState('');

  const onReceiveTicket = useMemoizedFn((ticket) => {
    setAuthTicket(ticket);
    setStep(2);
  });

  return (
    <Wrapper>
      <div className="forget-container" id="forget_container">
        <div className="welcome-message">
          <FormattedMessage id="account.welcome" />
        </div>
        <Tabs centered className="header-tabs" activeKey="forget">
          <Tabs.TabPane
            className="header-tab"
            tab={<FormattedMessage id="center.reset.password" />}
            key="forget"
          />
        </Tabs>
        {step === 1 && (
          <AddressConfirm wrapperRef={ref} onOk={onReceiveTicket} />
        )}
        {step === 2 && <ResetPassword authTicket={authTicket} />}
        <div className="compact-row flex-center">
          <Link
            to={{
              ...window.location,
              pathname: '/login',
            }}
            replace
          >
            <FormattedMessage id="account.login.existing.account" />
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default Forget;
