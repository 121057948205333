export function loadLocaleData(locale: string) {
  let message: Record<string, string> = {};
  const p = `./${locale}/`;
  const context = (require as any).context('../../../locales/dist/', true, /\.json$/);
  context.keys().forEach((key: string) => {
    if (new RegExp(`^${p}`).test(key)) {
      const obj = context(key)
      message = Object.assign({}, message, obj)
    }
  })
  return message;
}
