import { useContext } from 'react';
import { useCreation, useMemoizedFn } from 'ahooks';
import { Dropdown, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { HomeContext } from '@/context/context';
import './index.scss';

const UserDropdown = () => {
  const navigate = useNavigate();
  const { userInfo, intl } = useContext(HomeContext);
  const onLogout = useMemoizedFn(() => {
    navigate(
      {
        ...window.location,
        pathname: '/logout',
      },
      {
        replace: true,
      },
    );
  });

  const menu = useCreation(() => {
    return (
      <Menu
        items={[
          {
            key: 'displayName',
            label: <div>{userInfo.displayName}</div>,
          },
          {
            key: 'exit',
            label: (
              <div className="header-dropdown__logout" onClick={onLogout}>
                {intl.formatMessage({
                  id: 'center.logout',
                })}
              </div>
            ),
          },
        ]}
      />
    );
  }, [userInfo]);

  return (
    <Dropdown overlay={menu}>
      <div className="header-dropdown">
        <span className="header-dropdown__message">
          {intl.formatMessage({ id: 'center.my.account' })}
        </span>
        <DownOutlined />
      </div>
    </Dropdown>
  );
};

const LINK_MAP: any = {
  dev: {
    enms: 'http://enms-dev.eiot6.com',
  },
  daily: {
    enms: 'http://enms-daily.eiot6.com',
  },
  test: {
    enms: 'http://enms-tst.eiot6.com',
  },
  prod: {
    enms: 'https://enms.eiot6.com',
  },
};

const Header = () => {
  const { intl } = useContext(HomeContext);

  const links = useCreation(() => {
    return ['enms'].map((el) => ({
      name: intl.formatMessage({
        id: `center.link.${el}`,
      }),
      href: LINK_MAP[RUN_ENV]?.[el],
    }));
  }, []);

  return (
    <div className="header">
      <div className="header__logo">
        {intl.formatMessage({ id: 'center.header.title' })}
      </div>
      <div className="header__right-side">
        <UserDropdown />
        <div className="header__link">
          {links.map((link) => (
            <a
              key={link.name}
              className="header__link-item"
              rel="noreferrer"
              href={link.href}
              target="_blank"
            >
              {link.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
