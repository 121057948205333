import { FC, useContext } from 'react';
import { Form } from 'antd';
import { HomeContext } from '@/context/context';
import { CAPTCHA_LENGTH } from '@/utils/const';
import { FormItemProps } from 'antd/es/form/FormItem';

const FormItemCaptcha: FC<FormItemProps> = (props) => {
  const context = useContext(HomeContext);
  const { children, ...formItemProps } = props;

  return (
    <Form.Item
      validateFirst
      validateTrigger="onFinish"
      rules={[
        {
          required: true,
          message: `${context.intl.formatMessage({
            id: 'account.please.input',
          })}${context.intl.formatMessage({
            id: 'account.captcha',
          })}`,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (value.length < CAPTCHA_LENGTH) {
              return Promise.reject(
                new Error(
                  context.intl.formatMessage(
                    {
                      id: 'account.captcha.length.must.over',
                    },
                    {
                      len: CAPTCHA_LENGTH,
                    },
                  ),
                ),
              );
            } else if (!!value && !Number.isInteger(Number(value))) {
              return Promise.reject(
                new Error(
                  context.intl.formatMessage({
                    id: 'account.captcha.must.be.number',
                  }),
                ),
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
      {...formItemProps}
    >
      {children}
    </Form.Item>
  );
};

export default FormItemCaptcha;
