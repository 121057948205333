import { FC, useContext, useRef } from 'react';
import { useMemoizedFn, useMount, useSafeState, useUpdateEffect } from 'ahooks';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Input, Button, Tabs, message, Modal, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import * as Api from '@/api';
import { HomeContext } from '../../context/context';
import { REDIRECT_DELAY } from '@/utils/const';
import Captcha from '../../components/captcha';
import ConditionComponent from '@/components/condition-component';
import Wrapper from '@/components/login-wrapper';
import { inRedirectWhitelist } from '@/utils/verify';
import { asyncCookie } from '@/utils/cookie';
import {
  FormItemAccount,
  FormItemCaptcha,
  FormItemPassword,
} from '@/components/form-item';
import './index.scss';

type ILoginType = 'password' | 'captcha';

const Login: FC = () => {
  const navigate = useNavigate();
  const context = useContext(HomeContext);
  const ref = useRef(null);
  const [loading, setLoading] = useSafeState(false);
  const [loginType, setLoginType] = useSafeState<ILoginType>('password');
  const [isWaitForLink, setIsWaitForLink] = useSafeState(false);
  const [address, setAddress] = useSafeState('');
  const [modalApi, modalContext] = Modal.useModal();
  const [isPreCheck, setIsPreCheck] = useSafeState(false);

  const onFinish = useMemoizedFn(async (values) => {
    if (isWaitForLink) return;
    if (loginType === 'captcha' && !values.verificationCode) return;

    setLoading(true);
    let request: any = null;
    switch (loginType) {
      case 'password':
        request = Api.loginByPassword;
        break;
      case 'captcha':
        request = Api.loginByCaptcha;
        break;
    }
    const res = await request?.(values);
    if (res.success) {
      try {
        asyncCookie(res.result);
        setIsWaitForLink(true);
        message.success(
          context.intl.formatMessage({
            id: 'account.login.success',
          }),
        );
        setLoading(false);
        setTimeout(() => {
          setTimeout(() => {
            setIsWaitForLink(false);
            form.resetFields();
          }, 1000);
          if (inRedirectWhitelist(context.redirectUrl)) {
            window.location.replace(context.redirectUrl);
          } else {
            navigate(
              {
                ...window.location,
                search: '',
                pathname: '/center',
              },
              {
                replace: true,
              },
            );
          }
        }, REDIRECT_DELAY);
      } catch {
        setLoading(false);
      }
    } else {
      setLoading(false);

      if (res.code === 20001) {
        const { cancelApplyTime, cancelEffectTime } = res.result;
        // const key = `open${Date.now()}`;
        modalApi.warning({
          width: 500,
          title: <FormattedMessage id="account.login.destory.warning" />,
          content: (
            <div className="destory-content">
              <p className="destory-content__p">
                <b>
                  {context.intl.formatMessage(
                    {
                      id: 'account.login.destory.message',
                    },
                    {
                      date: dayjs(cancelApplyTime).format('YYYY年MM月DD日'),
                    },
                  )}
                </b>
              </p>
              <ol className="destory-content__list">
                <li>
                  {context.intl.formatMessage(
                    {
                      id: 'account.login.destory.desc.1',
                    },
                    {
                      date: dayjs(cancelEffectTime).format('YYYY年MM月DD日'),
                    },
                  )}
                </li>
                <li>
                  {context.intl.formatMessage(
                    {
                      id: 'account.login.destory.desc.2',
                    },
                    {
                      email: (
                        <a
                          href={`mailto:${context.intl.formatMessage({
                            id: 'account.email.address',
                          })}`}
                        >
                          <FormattedMessage id="account.email.address" />
                        </a>
                      ),
                    },
                  )}
                </li>
              </ol>
            </div>
          ),
          maskClosable: true,
          okText: context.intl.formatMessage({
            id: 'account.got.it',
          }),
        });
      }
    }
  });

  const [form] = Form.useForm();

  const onAddressChange = useMemoizedFn((e) => {
    setAddress(e.target.value);
  });

  useMount(async () => {
    try {
      const res = await context.setUserInfo();
      if (!!res) {
        if (!!context.redirectUrl) {
          window.location.replace(context.redirectUrl);
        } else {
          navigate(
            {
              ...window.location,
              pathname: '/center',
            },
            {
              replace: true,
            },
          );
        }
      }
    } catch (e) {
    } finally {
      setIsPreCheck(true);
    }
  });

  useUpdateEffect(() => {
    form.resetFields();
    setAddress('');
  }, [loginType]);

  if (!isPreCheck) return null;

  return (
    <Wrapper>
      <div ref={ref} className="login-container">
        <div className="welcome-message">
          <FormattedMessage id="account.welcome" />
        </div>
        <Tabs
          centered
          className="header-tabs"
          activeKey={loginType}
          onChange={setLoginType as any}
        >
          <Tabs.TabPane
            className="header-tab"
            tab={<FormattedMessage id="account.login.by.password" />}
            key="password"
          />
          <Tabs.TabPane
            tab={<FormattedMessage id="account.login.by.captcha" />}
            key="captcha"
          />
        </Tabs>
        <Form
          form={form}
          autoComplete="off"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
        >
          <FormItemAccount name="loginNo">
            <Input
              placeholder={`${context.intl.formatMessage({
                id: 'account.please.input',
              })}${context.intl.formatMessage({
                id: 'account.mobile.or.email',
              })}`}
              onChange={onAddressChange}
              allowClear
            />
          </FormItemAccount>
          <ConditionComponent
            condition={loginType === 'captcha'}
            truly={
              <>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            new Error(
                              context.intl.formatMessage({
                                id: 'account.agreement.required.message',
                              }),
                            ),
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>
                    <FormattedMessage id="account.agreement.agree" />
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://eiot6.com/docs/vgxeib?hide_aside=1"
                    >
                      {context.intl.formatMessage({
                        id: 'account.service.agreement',
                      })}
                    </a>
                    、
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://eiot6.com/docs/ogcp1s?hide_aside=1"
                    >
                      {context.intl.formatMessage({
                        id: 'account.privacy.policy',
                      })}
                    </a>
                  </Checkbox>
                </Form.Item>
                <FormItemCaptcha
                  name="verificationCode"
                  style={{ marginBottom: 0 }}
                >
                  <Captcha
                    scene="LOGIN"
                    wrapperRef={ref.current}
                    form={form}
                    address={address}
                    onFinish={form.submit}
                    verifyFields={['loginNo', 'agreement']}
                  />
                </FormItemCaptcha>
              </>
            }
            falsy={
              <FormItemPassword name="password">
                <Input.Password
                  placeholder={`${context.intl.formatMessage({
                    id: 'account.please.input',
                  })}${context.intl.formatMessage({
                    id: 'account.password',
                  })}`}
                  allowClear
                />
              </FormItemPassword>
            }
          />
          {loginType !== 'captcha' && (
            <>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            context.intl.formatMessage({
                              id: 'account.agreement.required.message',
                            }),
                          ),
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
                style={{ marginBottom: 0 }}
              >
                <Checkbox>
                  <FormattedMessage id="account.agreement.agree" />
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://eiot6.com/docs/vgxeib?hide_aside=1"
                  >
                    {context.intl.formatMessage({
                      id: 'account.service.agreement',
                    })}
                  </a>
                  、
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://eiot6.com/docs/ogcp1s?hide_aside=1"
                  >
                    {context.intl.formatMessage({
                      id: 'account.privacy.policy',
                    })}
                  </a>
                </Checkbox>
              </Form.Item>
              <Form.Item
                className="compact-row"
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
              >
                <Button
                  className="btn-login"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {context.intl.formatMessage({
                    id: isWaitForLink
                      ? 'account.login.success'
                      : 'account.login',
                  })}
                </Button>
              </Form.Item>
            </>
          )}
          <div className="compact-row bottom-operate">
            <div className="flex-for-item">
              <Link
                to={{
                  ...window.location,
                  pathname: '/register',
                }}
                replace
              >
                <FormattedMessage id="account.register.account" />
              </Link>
              <Link
                to={{
                  ...window.location,
                  pathname: '/forget',
                }}
                className="btn-link-forget"
              >
                <FormattedMessage id="account.forget.password" />
              </Link>
            </div>
          </div>
        </Form>
      </div>
      {modalContext}
    </Wrapper>
  );
};

export default Login;
