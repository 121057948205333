import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useMount } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import Header from '@/components/header';
import CustomerLink from '@/components/customer-link';
import { HomeContext } from '@/context/context';
import './index.scss';

const Center = () => {
  const context = useContext(HomeContext);

  useMount(() => {
    context.setUserInfo();
  });

  return (
    <div className="center">
      <Header />
      <main className="center-main">
        <nav className="center-nav">
          <ul className="center-nav__list">
            <li className="center-nav__item">
              <CustomerLink
                to="/center/basic"
                className="center-nav__item-link"
                activeClassName="center-nav__item-link-actived"
              >
                <FormattedMessage id="center.basic.info" />
              </CustomerLink>
            </li>
            <li className="center-nav__item">
              <CustomerLink
                to="/center/auth"
                className="center-nav__item-link"
                activeClassName="center-nav__item-link-actived"
              >
                <FormattedMessage id="center.auth.real.name" />
              </CustomerLink>
            </li>
          </ul>
        </nav>
        <div className="center-children">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Center;
