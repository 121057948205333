import React from 'react';
import { IntlShape } from 'react-intl';
import { UserInfo } from '@/utils/interface';

interface IHomeContext {
  redirectUrl: string;
  userInfo: UserInfo;
  setUserInfo: (val?: any) => Promise<UserInfo> | UserInfo | undefined;
  intl: IntlShape;
}

export const HomeContext = React.createContext<IHomeContext>({
  redirectUrl: '',
  userInfo: {
    account: '',
    accountId: '',
    gmtCreate: 0,
    email: '',
    isPasswordSetting: false,
    mobile: '',
    nickname: '',
    status: 1,
    displayName: '',
    appPermissions: 0,
    avatar: '',
    isPrimary: 1,
    tenantId: '',
  },
  setUserInfo: () => undefined,
  intl: {} as any,
});
