import { FC, useContext } from 'react';
import { Form } from 'antd';
import { HomeContext } from '@/context/context';
import { FormItemProps } from 'antd/es/form/FormItem';
import { LOGIN_PASSWORD_MIN_LENGTH } from '@/utils/const';

const FormItemPassword: FC<FormItemProps> = (props) => {
  const context = useContext(HomeContext);
  const { children, rules, ...formItemProps } = props;

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: `${context.intl.formatMessage({
            id: 'account.please.input',
          })}${context.intl.formatMessage({
            id: 'account.password',
          })}`,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!!value && value.length < LOGIN_PASSWORD_MIN_LENGTH) {
              return Promise.reject(
                new Error(
                  context.intl.formatMessage(
                    {
                      id: 'account.password.length.must.over',
                    },
                    {
                      len: LOGIN_PASSWORD_MIN_LENGTH,
                    },
                  ),
                ),
              );
            }
            return Promise.resolve();
          },
        }),
        ...(rules || []),
      ]}
      {...formItemProps}
    >
      {children}
    </Form.Item>
  );
};

export default FormItemPassword;
