import { FC } from 'react';
import './index.scss';

const IndexWrapper: FC = ({ children }) => {
  return (
    <div className="page">
      <div className="page-main">
        <div className="wrapper-container">{children}</div>
      </div>
    </div>
  );
};

export default IndexWrapper;
