import { useMount } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from '@/utils/cookie';
import * as Api from '@/api';

const Logout = () => {
  const navigate = useNavigate();

  useMount(async () => {
    await Api.logout();
    removeCookie();
    navigate(
      {
        ...window.location,
        pathname: '/login',
      },
      {
        replace: true,
      },
    );
  });

  return <div></div>;
};

export default Logout;
