import { FC, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import qs from 'query-string';
import { injectIntl, IntlShape } from 'react-intl';
import * as Api from '@/api';
import { HomeContext } from './context';

const Home: FC<{
  intl: IntlShape;
}> = ({ intl, children }) => {
  const [userInfo, updateUserInfo] = useState<any>({});
  const redirectUrl = decodeURIComponent(
    (qs.parse(window.location.search)?.redirect_url as string) || '',
  );

  const getUserInfo = useMemoizedFn(() => {
    return Api.getUserInfo();
  });

  const setUserInfo = useMemoizedFn((data) => {
    if (!!data) {
      updateUserInfo(data);
    } else {
      return getUserInfo().then((res) => {
        if (res.success) {
          updateUserInfo(res.result || {});
          return res.result;
        }
      }).catch((err) => {
        throw err;
      });
    }
  });

  return (
    <HomeContext.Provider
      value={{
        redirectUrl,
        userInfo,
        setUserInfo,
        intl,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default injectIntl(Home);
