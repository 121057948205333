import { FC, useContext } from 'react';
import { Modal, Form, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import CaptchaBtn from '../captcha-btn';
import SenderType from '../sender-type';
import FormItem from '../form-item';
import * as Api from '@/api';
import { HomeContext } from '@/context/context';
import { FormattedMessage } from 'react-intl';
import './index.scss';

const DestoryAccount: FC = (props) => {
  const navigate = useNavigate();
  const { intl, userInfo } = useContext(HomeContext);
  const { children } = props;

  const [form] = Form.useForm();
  const [formModal, setFormModal] = useSafeState(false);
  const [loading, setLoading] = useSafeState(false);
  const [modalApi, modalContext] = Modal.useModal();

  const [senderType, setSenderType] = useSafeState<
    Api.ICaptchaSenderType | undefined
  >(() => {
    return !!userInfo.mobile ? 'SMS' : 'EMAIL';
  });

  const onCaptchaFormValueChange = useMemoizedFn((e) => {
    setSenderType(e.senderType);
  });

  const onFinish = useMemoizedFn(async (values) => {
    setLoading(true);
    const res = await Api.getAuthTicket(values);
    if (res.success) {
      const authTicket = res.result.authTicket;
      const daRes = await Api.destoryAccount({
        authTicket,
      });
      if (daRes.success) {
        message.success(
          intl.formatMessage({
            id: 'center.destory.success',
          }),
        );
        setTimeout(() => {
          navigate(
            {
              ...window.location,
              pathname: '/login',
            },
            {
              replace: true,
            },
          );
        }, 300);
      }
    }
    setLoading(false);
  });

  const onPrepareDesotry = useMemoizedFn(() => {
    modalApi.confirm({
      width: 500,
      icon: <ExclamationCircleOutlined />,
      title: <FormattedMessage id="center.destory.tips.title" />,
      content: (
        <div className="destory-tips-content">
          <ol className="destory-tips-content__list">
            <li>
              {intl.formatMessage({
                id: 'center.destory.tips.desc.1',
              })}
            </li>
            <li>
              {intl.formatMessage(
                {
                  id: 'center.destory.tips.desc.2',
                },
                {
                  email: (
                    <a
                      href={`mailto:${intl.formatMessage({
                        id: 'account.email.address',
                      })}`}
                    >
                      <FormattedMessage id="account.email.address" />
                    </a>
                  ),
                },
              )}
            </li>
          </ol>
          <i className="destory-tips-content__split"></i>
          <b className="destory-tips-content__warning">
            <FormattedMessage id="center.destory.tips.warning.title" />
          </b>
          <div className="destory-tips-content__warning-tips">
            <FormattedMessage id="center.destory.tips.warning.tips" />
          </div>
          <div className="destory-tips-content__warning-desc">
            {intl
              .formatMessage({
                id: 'center.destory.tips.warning.desc',
              })
              .split('&&')
              .map((el) => (
                <p
                  key={el}
                  className="destory-tips-content__warning-desc__item"
                >
                  {el}
                </p>
              ))}
          </div>
        </div>
      ),
      maskClosable: true,
      closable: true,
      okText: intl.formatMessage({
        id: 'center.destory.account.btn',
      }),
      okButtonProps: {
        style: {
          width: 120,
        },
      },
      cancelButtonProps: {
        style: {
          width: 120,
        },
      },
      cancelText: intl.formatMessage({
        id: 'center.destory.account.cancel.btn',
      }),
      onOk: () => setFormModal(true),
    });
  });

  useMount(() => {
    setFormModal(false);
    setSenderType(undefined);
  });

  return (
    <>
      <Modal
        title={<FormattedMessage id="center.destory.account" />}
        visible={formModal}
        onCancel={() => setFormModal(false)}
        onOk={form.submit}
        confirmLoading={loading}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onValuesChange={onCaptchaFormValueChange}
        >
          <FormItem.SenderType
            name="senderType"
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: 'account.please.input',
                })}${intl.formatMessage({
                  id: 'account.find.verify.type',
                })}`,
              },
            ]}
          >
            <SenderType
              placeholder={`${intl.formatMessage({
                id: 'account.please.select',
              })}${intl.formatMessage({
                id: 'account.find.verify.type',
              })}`}
            />
          </FormItem.SenderType>
          <FormItem.Captcha name="code">
            <CaptchaBtn senderType={senderType} scene="ACCOUNT_CANCEL" />
          </FormItem.Captcha>
        </Form>
      </Modal>
      <div onClick={onPrepareDesotry}>{children}</div>
      {modalContext}
    </>
  );
};

export default DestoryAccount;
