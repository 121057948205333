import cookie from 'js-cookie';
/** 获取一级域名 */
export const getFirstDomain = () => {
  const host = window.location.hostname;
  const pattern = /([\d\w]+\.(com|xyz|top|cn|net|xin|pub|org))$/;
  return host.match(pattern)?.[0] || host;
};
export const asyncCookie = (token: string) => {
  const domain = getFirstDomain();
  cookie.set('access_token', token, {
    domain: domain !== window.location.hostname ? `.${domain}` : domain,
    expires: 999,
  });
  cookie.set('access_token', token, {
    expires: 999,
  });
};

export const removeCookie = () => {
  const domain = getFirstDomain();
  cookie.remove('access_token', {
    domain: domain !== window.location.hostname ? `.${domain}` : domain,
  });
  cookie.remove('access_token');
};
