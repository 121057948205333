import { useRef, FC, useContext, FocusEvent } from 'react';
import { createPortal } from 'react-dom';
import { Input, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMemoizedFn, useInterval, useSafeState } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import { FormInstance } from 'antd/lib/form/Form';
import * as Api from '@/api';
import { CAPTCHA_LENGTH } from '@/utils/const';
import { HomeContext } from '@/context/context';
import ConditionComponent from '@/components/condition-component';
import CaptchaText from '../captcha-text';
import './index.scss';

const Captcha: FC<{
  address: string;
  wrapperRef?: any;
  scene: Api.ICaptchaScene;
  onChange?: (captchaCode: string) => void;
  onFinish?: (captchaCode: string) => void;
  form?: FormInstance;
  verifyFields?: string[];
}> = (props, ref) => {
  const context = useContext(HomeContext);
  const {
    address,
    scene,
    onChange,
    wrapperRef,
    onFinish,
    children,
    form,
    verifyFields,
  } = props;
  const [captchaCode, setCaptchaCode] = useSafeState<string>('');
  const [loading, setLoading] = useSafeState(false);
  const [hiddenInputIsFocus, setHiddenInputIsFocus] = useSafeState(false);
  const hiddenInput = useRef<any>(null);
  const countdown = useRef<number>(60);
  const [captchaBtnText, setCaptchaBtnText] = useSafeState<string>(
    context.intl.formatMessage({
      id: 'account.getting.captcha',
    }),
  );
  const [interval, setInterval] = useSafeState<number | undefined>(undefined);

  const onCaptchaChange = useMemoizedFn((e) => {
    const {
      target: { value },
    } = e;
    setCaptchaCode(value);
    onChange?.(value);

    if (value.length === CAPTCHA_LENGTH) {
      onFinish?.(value);
    }
  });

  const catchHiddenInputFocusStatus = (e: FocusEvent) => {
    setHiddenInputIsFocus(e.type === 'focus');
  };

  useInterval(
    () => {
      if (interval !== undefined) {
        if (countdown.current > 1) {
          countdown.current -= 1;
          setCaptchaBtnText(`${countdown.current}`);
        } else {
          setInterval(undefined);
          countdown.current = 60;
          setCaptchaBtnText(
            context.intl.formatMessage({
              id: 'account.reacquire',
            }),
          );
        }
      } else {
        countdown.current = 60;
        setCaptchaBtnText(`${countdown.current}`);
      }
    },
    interval,
    {
      immediate: true,
    },
  );

  const getCaptchaCode = useMemoizedFn(async () => {
    setLoading(true);
    const res = await Api.getCaptcha({
      address,
      scene: scene,
    });

    if (res.success) {
      setInterval(1000);
    } else {
      setCaptchaBtnText(
        context.intl.formatMessage({
          id: 'account.reacquire',
        }),
      );
    }
    setLoading(false);

    return res.success;
  });

  const [nextStep, setNextStep] = useSafeState(false);

  const onNextStep = useMemoizedFn(async () => {
    if (form) {
      try {
        await form.validateFields(verifyFields || undefined);
      } catch (e) {
        console.log(e);
        return;
      }
    }
    const status = await getCaptchaCode();

    if (status) {
      setNextStep(true);
      setTimeout(() => {
        hiddenInput.current?.focus?.();
      }, 0);
    }
  });

  const onBack = useMemoizedFn(() => {
    setNextStep(false);
    setCaptchaCode('');
    onChange?.('');
  });

  if (!nextStep) {
    return (
      <Button
        type="primary"
        className="btn-next-step"
        block
        disabled={!address}
        loading={loading}
        onClick={onNextStep}
      >
        {context.intl.formatMessage({
          id: 'account.next.step',
        })}
      </Button>
    );
  }

  // antd form-item 自带position relative，没法使用absolute定位，因此通过portal直接挂载到外部
  return createPortal(
    <div className="captcha-row">
      <div className="captcha-row__header">
        <ArrowLeftOutlined
          className="captcha-row__header--icon-return"
          onClick={onBack}
        />
        <FormattedMessage id="account.enter.captcha" />
        <div className="captcha-row__header--send-tips">
          <FormattedMessage
            id="account.has.been.sent.to"
            values={{
              phone: address,
            }}
          />
        </div>
      </div>
      <div className="captcha-row__content">
        <Input
          onFocus={catchHiddenInputFocusStatus}
          onBlur={catchHiddenInputFocusStatus}
          ref={hiddenInput}
          className="captcha-row__content--hidden-captcha"
          onChange={onCaptchaChange}
          value={captchaCode}
          maxLength={CAPTCHA_LENGTH}
        />
        <CaptchaText
          inputFocus={hiddenInputIsFocus}
          text={captchaCode}
          maxlength={CAPTCHA_LENGTH}
        />
      </div>
      <div className="captcha-row__footer">
        <ConditionComponent
          condition={!!interval}
          truly={
            <FormattedMessage
              id="account.reacquire.after"
              values={{
                second: (
                  <span className="captcha-countdown__second">
                    {captchaBtnText}s
                  </span>
                ),
              }}
            />
          }
          falsy={
            <Button
              className="btn-captcha-get"
              type="link"
              loading={loading}
              onClick={getCaptchaCode}
            >
              {captchaBtnText}
            </Button>
          }
        />
      </div>
      {children}
    </div>,
    wrapperRef,
  );
};

export default Captcha;
