import {
  FC,
  useContext,
  useMemo,
  useImperativeHandle,
  useRef,
  forwardRef,
} from 'react';
import { Modal, Button, Input, message, Form } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useMemoizedFn, useSafeState } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import { HomeContext } from '@/context/context';
import * as Api from '@/api';
import CaptchaBtn from '@/components/captcha-btn';
import FormItem from '@/components/form-item';
import ConditionComponent from '../condition-component';
import { CaptchaType } from '@/utils/const';
import './index.scss';

const PreSendCaptcha = forwardRef<
  any,
  {
    type: CaptchaType;
    scene: Api.ICaptchaScene;
    onOk: Function;
    setLoading: Function;
  }
>((props, ref) => {
  const { type, scene, onOk, setLoading } = props;
  const context = useContext(HomeContext);
  const [form] = Form.useForm();

  const onFinish = useMemoizedFn(async (values) => {
    setLoading(true);
    const res = await Api.getAuthTicket({
      senderType: type === 'mobile' ? 'SMS' : 'EMAIL',
      code: values.code,
    });
    setLoading(false);
    if (res.success) {
      onOk(res.result.authTicket);
    }
  });

  useImperativeHandle(ref, () => ({
    form,
  }));

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item label={<FormattedMessage id="center.captcha.will.send.to" />}>
        {context.userInfo[type]}
      </Form.Item>
      <FormItem.Captcha name="code">
        <CaptchaBtn
          scene={scene}
          senderType={type === 'email' ? 'EMAIL' : 'SMS'}
        />
      </FormItem.Captcha>
    </Form>
  );
});

const ResetAddress = forwardRef<
  any,
  {
    type: CaptchaType;
    scene: Api.ICaptchaScene;
    onOk: Function;
    authTicket: string;
    setLoading: Function;
  }
>((props, ref) => {
  const { type, scene, authTicket, onOk, setLoading } = props;
  const context = useContext(HomeContext);
  const [form] = Form.useForm();
  const [address, setAddress] = useSafeState('');

  const onFinish = useMemoizedFn(async (values) => {
    setLoading(true);
    let res: any = null;
    switch (type) {
      case 'mobile':
        res = await Api.rebindMobile({
          authTicket,
          newMobile: address,
          verificationCode: values.verificationCode,
        });
        break;
      case 'email':
        res = await Api.rebindEmail({
          authTicket,
          newEmail: address,
          verificationCode: values.verificationCode,
        });
        break;
    }

    if (res.success) {
      message.success(
        context.intl.formatMessage({
          id: 'center.reset.success',
        }),
      );
      onOk();
      context.setUserInfo(res.result);
    }

    setLoading(false);
  });

  useImperativeHandle(ref, () => ({
    form,
  }));

  const onAddressChange = useMemoizedFn((e) => setAddress(e.target.value));

  return (
    <Form form={form} onFinish={onFinish}>
      <FormItem.Account name={type === 'email' ? 'newEmail' : 'newMobile'}>
        <Input
          allowClear
          maxLength={type === 'mobile' ? 11 : undefined}
          placeholder={`${context.intl.formatMessage({
            id: 'account.please.input',
          })}${context.intl.formatMessage({
            id: `center.${type}`,
          })}`}
          onChange={onAddressChange}
        />
      </FormItem.Account>
      <FormItem.Captcha name="verificationCode">
        <CaptchaBtn scene={scene} address={address} />
      </FormItem.Captcha>
    </Form>
  );
});

const ResetEmailOrMobileModal: FC<{ type: CaptchaType } & ModalProps> = (
  props,
) => {
  const context = useContext(HomeContext);
  const { type, ...modalProps } = props;

  const [step, setStep] = useSafeState<1 | 2>(1);
  const captchaRef = useRef<any>(null);
  const addressRef = useRef<any>(null);
  const [authTicket, setAuthTicket] = useSafeState('');
  const [loading, setLoading] = useSafeState(false);

  const onNext = useMemoizedFn(() => {
    if (step === 1) {
      captchaRef.current.form.submit();
    } else if (step === 2) {
      addressRef.current.form.submit();
    }
  });

  const scene = useMemo(() => {
    return type === 'mobile' ? 'REBIND_MOBILE' : 'REBIND_EMAIL';
  }, [type]);

  const onCancel = useMemoizedFn((e) => {
    setStep(1);
    props.onCancel?.(e);
    context.setUserInfo();
    setAuthTicket('');
    captchaRef.current.form.resetFields();
    addressRef.current.form.resetFields();
  });

  const onPrevCaptchaOk = useMemoizedFn((authTicket: string) => {
    setAuthTicket(authTicket);
    setStep(2);
  });

  return (
    <Modal
      destroyOnClose
      {...modalProps}
      onCancel={onCancel}
      className="reset-email-or-mobile"
      footer={
        <Button type="primary" onClick={onNext} loading={loading}>
          {context.intl.formatMessage({
            id: 'center.next.step',
          })}
        </Button>
      }
    >
      <ConditionComponent
        condition={step === 1}
        truly={
          <PreSendCaptcha
            type={type}
            scene={scene}
            onOk={onPrevCaptchaOk}
            ref={captchaRef}
            setLoading={setLoading}
          />
        }
        falsy={
          <ResetAddress
            type={type}
            scene={scene}
            authTicket={authTicket}
            ref={addressRef}
            onOk={onCancel}
            setLoading={setLoading}
          />
        }
      />
    </Modal>
  );
};

const ResetEmailOrMobile: FC<{
  type: CaptchaType;
}> = ({ type }) => {
  const context = useContext(HomeContext);
  const [visible, setVisible] = useSafeState(false);

  const onEditClick = useMemoizedFn(() => {
    setVisible(true);
  });

  const onClose = useMemoizedFn(() => {
    setVisible(false);
  });

  return (
    <>
      <ResetEmailOrMobileModal
        type={type}
        destroyOnClose
        title={context.intl.formatMessage({
          id: `center.reset.${type}`,
        })}
        visible={visible}
        onCancel={onClose}
      />
      <Button onClick={onEditClick}>
        {context.intl.formatMessage({
          id: 'center.edit',
        })}
      </Button>
    </>
  );
};

export default ResetEmailOrMobile;
