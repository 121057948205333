import { FC, ReactElement } from 'react';
import './index.scss';

const CenterRow: FC<{
  title?: string | ReactElement;
  desc?: string | ReactElement;
  descExtra?: ReactElement;
}> = ({ title, desc, descExtra }) => {
  return (
    <div className="center-row">
      {!!title && <div className="center-row__title">{title}</div>}
      {!!desc && (
        <div
          className={`center-row__desc ${
            descExtra ? 'center-row__desc-flex' : ''
          }`}
        >
          <div className="desc-content">{desc}</div>
          <div className="desc-extra">{descExtra}</div>
        </div>
      )}
    </div>
  );
};

export default CenterRow;
