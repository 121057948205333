import { FC, useContext } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { FormattedMessage } from 'react-intl';
import { HomeContext } from '@/context/context';
import './index.scss';

interface SenderTypeProps extends SelectProps {
  valueType?: 'type' | 'value';
}

const SenderType: FC<SenderTypeProps> = (props) => {
  const context = useContext(HomeContext);
  const { valueType = 'type', ...lastProps } = props;

  return (
    <Select
      className="select-captcha-way"
      placeholder={`${context.intl.formatMessage({
        id: 'account.please.input',
      })}${context.intl.formatMessage({
        id: 'account.find.password.type',
      })}`}
      {...lastProps}
    >
      {!!context.userInfo.mobile && (
        <Select.Option
          key={valueType === 'type' ? 'SMS' : context.userInfo.mobile}
        >
          <FormattedMessage id="center.mobile" />: {context.userInfo.mobile}
        </Select.Option>
      )}
      {!!context.userInfo.email && (
        <Select.Option
          key={valueType === 'type' ? 'EMAIL' : context.userInfo.email}
        >
          <FormattedMessage id="center.email" />: {context.userInfo.email}
        </Select.Option>
      )}
    </Select>
  );
};

export default SenderType;
