import { FC, useContext } from 'react';
import { flushSync } from 'react-dom';
import { Modal, Button, Input, message, Form } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useMemoizedFn, useSafeState, useUpdateEffect } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import { HomeContext } from '@/context/context';
import * as Api from '@/api';
import CaptchaBtn from '@/components/captcha-btn';
import FormItem from '@/components/form-item';
import ConditionComponent from '../condition-component';
import SenderType from '../sender-type';

const ResetPasswordModal: FC<ModalProps & { onCancel: () => void }> = (
  props,
) => {
  const context = useContext(HomeContext);

  const [step, setStep] = useSafeState<1 | 2>(1);
  const [authTicket, setAuthTicket] = useSafeState('');
  const [loading, setLoading] = useSafeState(false);

  const [senderType, setSenderType] = useSafeState<Api.ICaptchaSenderType>(
    () => {
      return !!context.userInfo.mobile ? 'SMS' : 'EMAIL';
    },
  );

  useUpdateEffect(() => {
    setSenderType(context.userInfo.mobile ? 'SMS' : 'EMAIL');
  }, [context.userInfo]);

  const onCancel = useMemoizedFn(() => {
    flushSync(() => {
      setSenderType(context.userInfo.mobile ? 'SMS' : 'EMAIL');
    });
    captchaForm.resetFields();
    passwordForm.resetFields();
    props.onCancel?.();
    setStep(1);
  });

  const [captchaForm] = Form.useForm();
  const [passwordForm] = Form.useForm();

  const onCaptchaFormValueChange = useMemoizedFn((e) => {
    setSenderType(e.senderType);
  });

  const getAuthTicket = useMemoizedFn(async (values) => {
    setLoading(true);
    const res = await Api.getAuthTicket(values);
    setLoading(false);
    if (res.success) {
      setAuthTicket(res.result.authTicket);
      setStep(2);
    }
  });

  const resetPassword = useMemoizedFn(async (values) => {
    setLoading(true);
    const res = await Api.resetPassword({
      authTicket,
      ...values,
    });

    if (res.success) {
      message.success(
        context.intl.formatMessage({
          id: 'center.reset.success',
        }),
      );
      context.setUserInfo();
      onCancel();
    }
    setLoading(false);
  });

  const onNext = useMemoizedFn(() => {
    if (step === 1) {
      captchaForm.submit();
    } else {
      passwordForm.submit();
    }
  });

  return (
    <Modal
      {...props}
      destroyOnClose
      className="reset-password"
      onCancel={onCancel}
      footer={
        <Button type="primary" onClick={onNext} loading={loading}>
          {context.intl.formatMessage({
            id: step === 1 ? 'center.next.step' : 'center.submit',
          })}
        </Button>
      }
    >
      <ConditionComponent
        condition={step === 1}
        truly={
          <div>
            <div className="select-captcha-way-title">
              <FormattedMessage id="center.select.captcha.acquire.way" />:
            </div>
            <Form
              form={captchaForm}
              onFinish={getAuthTicket}
              onValuesChange={onCaptchaFormValueChange}
            >
              <FormItem.SenderType name="senderType" initialValue={senderType}>
                <SenderType />
              </FormItem.SenderType>
              <FormItem.Captcha name="code">
                <CaptchaBtn scene="RESET_PASSWORD" senderType={senderType} />
              </FormItem.Captcha>
            </Form>
          </div>
        }
        falsy={
          <Form form={passwordForm} onFinish={resetPassword}>
            <FormItem.Password name="newPassword">
              <Input.Password
                placeholder={`${context.intl.formatMessage({
                  id: 'account.please.input',
                })}${context.intl.formatMessage({
                  id: 'account.password',
                })}`}
              />
            </FormItem.Password>
          </Form>
        }
      />
    </Modal>
  );
};

const ResetPassword: FC = () => {
  const context = useContext(HomeContext);
  const [visible, setVisible] = useSafeState(false);

  const onEditClick = useMemoizedFn(() => {
    setVisible(true);
  });

  const onClose = useMemoizedFn(() => {
    setVisible(false);
  });

  return (
    <>
      <ResetPasswordModal
        destroyOnClose
        title={context.intl.formatMessage({
          id: 'center.reset.password',
        })}
        visible={visible}
        onCancel={onClose}
      />
      <Button onClick={onEditClick}>
        {context.intl.formatMessage({
          id: 'center.edit',
        })}
      </Button>
    </>
  );
};

export default ResetPassword;
