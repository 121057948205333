import { FC, useContext } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useMemoizedFn, useSafeState } from 'ahooks';
import { HomeContext } from '@/context/context';
import * as Api from '@/api';
import CaptchaBtn from '@/components/captcha-btn';
import FormItem from '@/components/form-item';
import { CaptchaType } from '@/utils/const';

const BindEmailOrMobileModal: FC<{ type: CaptchaType } & ModalProps> = (
  props,
) => {
  const context = useContext(HomeContext);
  const { type, ...modalProps } = props;
  const [loading, setLoading] = useSafeState(false);

  const [form] = Form.useForm();

  const onFinish = useMemoizedFn(async (values) => {
    setLoading(true);
    let res: any = null;
    switch (type) {
      case 'mobile':
        res = await Api.rebindMobile(values);
        break;
      case 'email':
        res = await Api.rebindEmail(values);
        break;
      default:
        res = await Api.rebindEmail(values);
        break;
    }

    if (res.success) {
      form.resetFields();
      context.setUserInfo();
      message.success(
        context.intl.formatMessage({
          id: 'center.bind.success',
        }),
      );
    }
    setLoading(false);
  });

  const onOk = useMemoizedFn(() => {
    form.submit();
  });

  const onCancel = useMemoizedFn((e) => {
    form.resetFields();
    modalProps.onCancel?.(e);
  });

  const [address, setAddress] = useSafeState('');

  return (
    <Modal
      {...modalProps}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={onFinish}>
        <FormItem.Account name={type === 'mobile' ? 'newMobile' : 'newEmail'}>
          <Input
            allowClear
            maxLength={type === 'mobile' ? 11 : undefined}
            onChange={(e) => setAddress(e.target.value)}
            placeholder={`${context.intl.formatMessage({
              id: 'account.please.input',
            })}${context.intl.formatMessage({
              id: `center.${type}`,
            })}`}
          />
        </FormItem.Account>
        <FormItem.Captcha name="verificationCode">
          <CaptchaBtn
            address={address}
            scene={type === 'mobile' ? 'REBIND_MOBILE' : 'REBIND_EMAIL'}
          />
        </FormItem.Captcha>
      </Form>
    </Modal>
  );
};

const BindEmailOrMobile: FC<{
  type: CaptchaType;
}> = ({ type }) => {
  const context = useContext(HomeContext);
  const [visible, setVisible] = useSafeState(false);

  const onEditClick = useMemoizedFn(() => {
    setVisible(true);
  });

  const onClose = useMemoizedFn(() => {
    setVisible(false);
  });

  return (
    <>
      <BindEmailOrMobileModal
        type={type}
        destroyOnClose
        title={context.intl.formatMessage({
          id: `center.bind.${type}`,
        })}
        visible={visible}
        onCancel={onClose}
      />
      <Button onClick={onEditClick}>
        {context.intl.formatMessage({
          id: 'center.bind',
        })}
      </Button>
    </>
  );
};

export default BindEmailOrMobile;
