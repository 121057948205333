import { useContext } from 'react';
import { Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { HomeContext } from '@/context/context';
import CenterRow from '../components/center-row';
import ResetPassword from '@/components/reset-password';
import ResetEmailOrMobile from '@/components/reset-email-or-mobile';
import BindEmailOrMobile from '@/components/bind-email-or-mobile';
import ConditionComponent from '@/components/condition-component';
import DestoryAccount from '@/components/destory-account';
import { FormattedMessage } from 'react-intl';
import './index.scss';

const CenterBasic = () => {
  const { intl, userInfo } = useContext(HomeContext);

  return (
    <>
      <div className="basic-header">
        {intl.formatMessage({ id: 'center.basic.info' })}
      </div>
      <CenterRow
        title={intl.formatMessage({
          id: 'center.register.date',
        })}
        desc={moment(userInfo.gmtCreate).format('YYYY-MM-DD HH:mm:SS')}
      />
      <CenterRow
        title={intl.formatMessage({
          id: 'center.reset.password',
        })}
        desc={<FormattedMessage id="center.password.desc" />}
        descExtra={<ResetPassword />}
      />
      <CenterRow
        title={intl.formatMessage({
          id: 'center.mobile',
        })}
        desc={
          <ConditionComponent
            condition={!!userInfo.mobile}
            truly={<span>{userInfo.mobile}</span>}
            falsy={
              <>
                <div className="no-binding-tips">
                  <ExclamationCircleOutlined style={{ marginRight: 3 }} />
                  <FormattedMessage id="center.no.bind" />
                </div>
                <FormattedMessage id="center.bind.mobile.desc" />
              </>
            }
          />
        }
        descExtra={
          <ConditionComponent
            condition={!!userInfo.mobile}
            truly={<ResetEmailOrMobile type="mobile" />}
            falsy={<BindEmailOrMobile type="mobile" />}
          />
        }
      />
      <CenterRow
        title={intl.formatMessage({
          id: 'center.email',
        })}
        desc={
          <ConditionComponent
            condition={!!userInfo.email}
            truly={<span>{userInfo.email}</span>}
            falsy={
              <>
                <div className="no-binding-tips">
                  <ExclamationCircleOutlined style={{ marginRight: 3 }} />
                  <FormattedMessage id="center.no.bind" />
                </div>
                <FormattedMessage id="center.bind.email.desc" />
              </>
            }
          />
        }
        descExtra={
          <ConditionComponent
            condition={!!userInfo.email}
            truly={<ResetEmailOrMobile type="email" />}
            falsy={<BindEmailOrMobile type="email" />}
          />
        }
      />
      <CenterRow
        title={intl.formatMessage({
          id: 'center.auth.real.name',
        })}
        desc={intl.formatMessage({
          id: 'center.auth.real.name.not.open',
        })}
      />
      <CenterRow
        title={intl.formatMessage({
          id: 'center.destory.account',
        })}
        desc={
          <>
            {intl
              .formatMessage({
                id: 'center.destory.account.desc',
              })
              .split('&&')
              .map((el, idx) => (
                <div key={idx}>{el}</div>
              ))}
          </>
        }
        descExtra={
          <DestoryAccount>
            <Button>
              {intl.formatMessage({
                id: 'center.destory.account.btn',
              })}
            </Button>
          </DestoryAccount>
        }
      />
    </>
  );
};

export default CenterBasic;
