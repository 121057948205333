import axios, { AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import { AXIOS_MESSAGE_CODE_WHITE_LIST } from '@/utils/const';

export const getApiDomain = () => {
  const isInc = window.location.host.includes('-inc.eiot6.com')
  const mapper: any = {
    dev: 'api-dev.eiot6.com',
    test: 'api-test.eiot6.com',
    prod: 'api.eiot6.com'
  }
  return isInc ? mapper[RUN_ENV].replace('.eiot6.com', '-inc.eiot6.com') : mapper[RUN_ENV]
}

export function getApiUrl () {
  return window.location.protocol + '//' + getApiDomain() + '/sso'
}

// const API_MAP: any = {
//   dev: 'http://api.dev.eiot6.com/sso',
//   daily: 'http://api.daily.eiot6.com/sso',
//   // 'dev': 'http://192.168.0.14:8100/sso',
//   prod: 'https://api.eiot6.com/sso',
// };

const headers: any = {
  'Content-Type': 'application/json',
  // 日常环境直接添加token头
  // 'x-sso-access-token':
  //   'deveyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzc29Db250ZXh0IjoiNWQ2ZjBkMzY0NWFjNGI1MzM3ZGE4ZTMwOGFjYjVjMzI3ZDEzOGQ3OTMyNmI5ZjU2OTRkMjUyNDYzN2ZhYTM5YjM1NDQ1OGIzODhmM2NlNjhiNmMxYjMxNWM1MzJkY2UxMGEwZTVhYWE1NjRiMDI1NzM4Mzg2M2VkMWY4YmNlZjUyN2JiMjNiMmJjNmI0N2IzZDlkMWRiNzU3YTA5MDg1NjYxNTMyOTYxZTI1ZDQ4YTQ5NzI4MWZiOTI5NzJjZmZhZmE2NTQ5NjAyZWE2Nzk2YmViMDkzOGY3N2I3M2MyODY5YTNmNzU3MGNhZWI5YzZlMDMwMmNiNDJkYjkxOWFhMjZkZDBjNGM5ZGFkN2UzOThkM2Y3NDlhZjQ3M2NmZjMzNTFmMTU1ZmRhYTZkYmI3ODI2MWQyMTUzZTc2NjJkMDY0NjQzZTQwNDIwYjE2MjdjMjM5OTViYWVkY2U3NzY5YmM5ZjFjNjQ5ODFjZTI3NTY3MjczOWEwNDczYzQ0OGIyOGJmMmQ4OWM1OTFiZmRmNjczYzRkNDVlOGU0YjNjNDIyM2ViYjdjOTAwN2FjMDM4MGE4YjY0MTA4NzgzZWMyNTgzZGNlNmQ2ODJmNzVlOWQ3NTYzZDE1ODE3MGFmMzc2OTI4Y2E2YWRjOTU3OTM0ZTA3ZTZjMDFmYjUyZTdmYmIxYzI0MDE2ODM4YzJkODQ5YmM3NjBkMzZlMWUxZDc0ZCIsImV4cCI6MTY3MDU2NjIzNX0.uw05Un387vcE085qS5QOzzXD81fk5ledrsNeIGIOJTo',
};

if (!window.location.origin.includes('localhost')) {
  delete headers['x-sso-access-token'];
}

export const axiosInstance = axios.create({
  baseURL: getApiUrl(),
  headers,
  transformRequest: (data) => {
    try {
      if (!data || typeof data !== 'object') return data;

      const res = Object.entries(data).reduce(
        (prev: any, [key, value]: any) => {
          if (value !== null && value !== undefined) {
            return {
              ...prev,
              [key]: value,
            };
          } else {
            return prev;
          }
        },
        {},
      );
      return JSON.stringify(res);
    } catch {
      return data;
    }
  },
  transformResponse: (data) => {
    try {
      if (!data || typeof data !== 'string') return data;
      const res = JSON.parse(data);
      return res;
    } catch {
      return data;
    }
  },
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (res) => {
    try {
      if (res.status === 200 && !res.data.success) {
        if (!AXIOS_MESSAGE_CODE_WHITE_LIST.includes(res.data?.code)) {
          message.error(res.data.message || '请求异常，请稍后重试');
        }
      }
      return res;
    } catch {
      return res;
    }
  },
  (err) => {
    if (err?.response?.status === 401) {
      if (window.location.pathname !== '/login') {
        window.location.replace(`/login${window.location.search || ''}`);
      }
    } else {
      message.error(err.response.message || '请求异常，请稍后重试');
    }
    return Promise.reject(err);
  },
);

class Request {
  mergeDataToConfig(data?: any, config?: AxiosRequestConfig) {
    if (!!data) {
      if (!!config) {
        config.params = data;
      } else {
        config = {
          params: data,
        };
      }
    }
    return config;
  }

  async get(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance
      .get(url, this.mergeDataToConfig(data, config))
      .then((res) => res.data)
      .catch((err) => err);
  }

  async post(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance
      .post(url, data, config)
      .then((res) => res.data)
      .catch((err) => err);
  }

  async put(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance
      .put(url, data, config)
      .then((res) => res.data)
      .catch((err) => err);
  }

  async delete(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance
      .delete(url, this.mergeDataToConfig(data, config))
      .then((res) => res.data)
      .catch((err) => err);
  }
}

export const request = new Request();

export * from './user';
