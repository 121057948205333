import { FC, ReactElement } from 'react';

interface IConditionComponent {
  condition: boolean;
  truly: ReactElement;
  falsy: ReactElement;
}

const ConditionComponent: FC<IConditionComponent> = (props) => {
  const { condition, truly, falsy } = props;

  return condition ? truly : falsy;
};

export default ConditionComponent;
