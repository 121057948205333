import { REDIRECT_WHITE_LIST } from './const';

export const inRedirectWhitelist = (url: string): boolean => {
  try {
    const { hostname } = new URL(url);
    return REDIRECT_WHITE_LIST.some((el) => hostname.includes(el));
  } catch (e) {
    return false;
  }
};
