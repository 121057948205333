import { useRef, FC, useContext } from 'react';
import { Input, Button, message, Form } from 'antd';
import { useMemoizedFn, useInterval, useSafeState } from 'ahooks';
import * as Api from '@/api';
import { HomeContext } from '@/context/context';
import { CAPTCHA_LENGTH } from '@/utils/const';
import './index.scss';

const CaptchaBtn: FC<{
  address?: string;
  scene: Api.ICaptchaScene;
  senderType?: Api.ICaptchaSenderType;
  onChange?: (v: string) => void;
}> = (props) => {
  const form = Form.useFormInstance();
  const context = useContext(HomeContext);
  const { address, scene, senderType, onChange } = props;
  const [captchaCode, setCaptchaCode] = useSafeState<string>('');
  const [loading, setLoading] = useSafeState(false);
  const countdown = useRef<number>(60);
  const [captchaBtnText, setCaptchaBtnText] = useSafeState<string>(
    context.intl.formatMessage({
      id: 'center.click.to.acquire',
    }),
  );
  const [interval, setInterval] = useSafeState<number | undefined>(undefined);
  const onCaptchaChange = useMemoizedFn((e) => {
    setCaptchaCode(e.target.value);
    onChange?.(e.target.value);
  });
  useInterval(
    () => {
      if (interval !== undefined) {
        if (countdown.current > 1) {
          countdown.current -= 1;
          setCaptchaBtnText(`${countdown.current}s`);
        } else {
          setInterval(undefined);
          countdown.current = 60;
          setCaptchaBtnText(
            context.intl.formatMessage({
              id: 'account.reacquire',
            }),
          );
        }
      } else {
        countdown.current = 60;
        setCaptchaBtnText(`${countdown.current}s`);
      }
    },
    interval,
    {
      immediate: true,
    },
  );
  const getCaptchaCode = useMemoizedFn(async () => {
    // 直接点击获取并且没有senderType需要校验address
    if (!senderType) {
      try {
        await form?.validateFields([
          scene === 'REBIND_MOBILE' ? 'newMobile' : 'newEmail',
        ]);
      } catch {
        return;
      }
    }

    setLoading(true);
    let res: any = null;
    if (!!address) {
      res = await Api.getCaptcha({
        address,
        scene,
      });
    } else if (senderType) {
      res = await Api.getCaptchWithToken({
        scene,
        senderType,
      });
    }
    if (res?.success) {
      setInterval(1000);
      message.success(
        context.intl.formatMessage({
          id: 'center.captcha.has.sent',
        }),
      );
    }
    setLoading(false);
  });
  return (
    <div className="captcha-btn-row">
      <Input
        onChange={onCaptchaChange}
        className="captcha-btn-row__input"
        value={captchaCode}
        maxLength={CAPTCHA_LENGTH}
        placeholder={`${context.intl.formatMessage({
          id: 'account.please.input',
        })}${context.intl.formatMessage({
          id: 'account.captcha',
        })}`}
        allowClear
      />
      <Button
        className="captcha-btn-row__btn"
        disabled={!!interval}
        loading={loading}
        onClick={getCaptchaCode}
      >
        {captchaBtnText}
      </Button>
    </div>
  );
};
export default CaptchaBtn;
