import { FC, useContext } from 'react';
import { Form } from 'antd';
import { HomeContext } from '@/context/context';
import { FormItemProps } from 'antd/es/form/FormItem';

const FormItemAccount: FC<FormItemProps> = (props) => {
  const context = useContext(HomeContext);
  const { children, ...formItemProps } = props;

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: `${context.intl.formatMessage({
            id: 'account.please.input',
          })}${context.intl.formatMessage({
            id: 'account.mobile.or.email',
          })}`,
        },
      ]}
      {...formItemProps}
    >
      {children}
    </Form.Item>
  );
};

export default FormItemAccount;
