
import { request } from './index';

export type ICaptchaScene =
  | 'LOGIN'
  | 'REGISTER'
  | 'RESET_PASSWORD'
  | 'REBIND_MOBILE'
  | 'REBIND_EMAIL'
  | 'ACCOUNT_CANCEL';
export type ICaptchaSenderType = 'EMAIL' | 'SMS';

export const loginByPassword = (data: { loginNo: string; password: string }) =>
  request.post('/login/password', data);

export const loginByCaptcha = (data: {
  loginNo: string;
  verificationCode: string;
}) => request.post('/login/verificationCode', data);

export const register = (data: {
  registerNo: string;
  password: string;
  captcha: string;
}) => request.post('/register/do', data);

export const getCaptcha = (data: { address: string; scene: ICaptchaScene }) =>
  request.post('/verification/sendCodeWithoutLogin', data);

export const getCaptchWithToken = (data: {
  scene: ICaptchaScene;
  senderType: ICaptchaSenderType;
}) => request.post('/verification/sendCode', data);

export const getUserInfo = () => request.get('/account/getAccount');

export const getAuthTicket = (data: {
  senderType?: ICaptchaSenderType;
  address?: string;
  code: string;
}) => request.post('/verification/exchangeAuthTicket', data);

export const resetPassword = (data: {
  authTicket: string;
  newPassword: string;
}) => request.post('/account/resetPassword', data);

export const rebindMobile = (data: {
  authTicket?: string; // 首次绑定不填
  newMobile: string;
  verificationCode: string;
}) => request.post('/account/rebindMobile', data);

export const rebindEmail = (data: {
  authTicket?: string; // 首次绑定不填
  newEmail: string;
  verificationCode: string;
}) => request.post('/account/rebindEmail', data);

export const destoryAccount = (data: { authTicket: string }) =>
  request.post('/account/cancel', data);

export const logout = () => request.get('/login/logout');
