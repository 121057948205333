import { useMemoizedFn } from 'ahooks';
import { FC } from 'react';
import './index.scss';

type CaptchaTextType = 'focus' | 'text' | 'empty';

const CaptchaTextItem: FC<{
  text: string;
  type: CaptchaTextType;
  blink?: boolean;
}> = (props) => {
  const { text, type, blink } = props;

  return (
    <span
      className={`captcha-text-item captcha-text-item__${type} ${
        blink && type === 'focus' ? 'captcha-text-item__blink' : ''
      }`}
    >
      {text}
    </span>
  );
};

interface CaptchaTextProps {
  text: string;
  maxlength: number;
  inputFocus?: boolean;
}
const CaptchaText: FC<CaptchaTextProps> = (props) => {
  const { text, maxlength, inputFocus } = props;

  const getArrayByLen = useMemoizedFn((len) => {
    return new Array(len).fill('');
  });

  return (
    <div className="captcha-text">
      {text.split('').map((el, idx) => {
        return <CaptchaTextItem key={idx} text={el} type="text" />;
      })}
      {getArrayByLen(Number(maxlength) - text.length).map((el, idx) => {
        return (
          <CaptchaTextItem
            key={idx}
            text={el}
            blink={inputFocus}
            type={idx === 0 ? 'focus' : 'empty'}
          />
        );
      })}
    </div>
  );
};

export default CaptchaText;
