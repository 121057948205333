import FormItemCaptcha from './captcha';
import FormItemAccount from './account';
import FormItemPassword from './password';
import FormItemSenderType from './sender-type';

const FormItem = {
  Captcha: FormItemCaptcha,
  Account: FormItemAccount,
  Password: FormItemPassword,
  SenderType: FormItemSenderType,
};

export {
  FormItemCaptcha,
  FormItemAccount,
  FormItemPassword,
  FormItemSenderType,
};

export default FormItem;
