import { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';

interface CustomerLinkProps {
  to: string;
  activeClassName?: string;
  className?: string;
  [key: string]: any;
}
const CustomerLink: FC<CustomerLinkProps> = (props) => {
  const { to, activeClassName = '', className = '', children, ...lastProps } = props;
  const match = useMatch(to);

  return (
    <Link
      to={to}
      className={!!match ? `${className} ${activeClassName}` : className}
      {...lastProps}
    >
      {children}
    </Link>
  );
};

export default CustomerLink;
