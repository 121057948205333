import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import zhCN from 'antd/lib/locale/zh_CN';
import { createRoot } from 'react-dom/client';
import { loadLocaleData } from '@/utils/i18n';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Login from './pages/login';
import Register from './pages/register';
import Forget from './pages/forget';
import Center from './pages/center';
import CenterBasic from './pages/center/basic';
import CenterAuth from './pages/center/auth';
import Logout from './pages/logout';
import RouterGuadian from './components/router-guadian';
import GlobalContext from './context';
import './index.css';

const root = createRoot(document.getElementById('root')!);
const message = loadLocaleData('zh_CN');

root.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <IntlProvider messages={message} locale="zh" defaultLocale="en">
        <BrowserRouter>
          <RouterGuadian>
            <GlobalContext>
              <Routes>
                <Route path="/">
                  <Route index element={<Login />} />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="forget" element={<Forget />} />
                  <Route path="center" element={<Center />}>
                    <Route path="basic" element={<CenterBasic />} />
                    <Route path="auth" element={<CenterAuth />} />
                  </Route>
                  <Route path="logout" element={<Logout />} />
                </Route>
              </Routes>
            </GlobalContext>
          </RouterGuadian>
        </BrowserRouter>
      </IntlProvider>
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
