import { FormattedMessage } from 'react-intl';
import './index.scss';

const CenterAuth = () => {
  return (
    <div className="center-auth">
      <div className="no-auth-img"></div>
      <div className="no-auth-tips">
        <FormattedMessage id="center.auth.no.open.desc" />
      </div>
    </div>
  );
};

export default CenterAuth;
